import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
  <Layout location="services">
    <SEO title="Services" />
    <div className="mx-auto prose lg:prose-lg divide-y divide-gray-200">
      <article>
        <h1>Services</h1>
        <p>
          I offer a confidential and safe space for you to talk about current
          concerns or difficulties. Warmth and compassion are at the heart of
          what I offer, where the aim is for us to create an understanding of
          what is going on for you at the moment and any experiences you may
          have had, or continue to have, that have led to feeling this way or
          that keep the difficulties going.
        </p>
        <p>
          Although the process of creating this understanding can be unsettling,
          this is very much about us bringing a curious and non-judgemental
          approach to your experience &mdash; about noticing what has (or has
          had) an impact and knowing that we all use a variety of coping
          strategies to overcome painful or challenging parts of our lives.
        </p>
        <p>
          Sometimes, as our needs change, the usefulness of these strategies
          shift &mdash; and there is no judgement in having changing needs or
          goals. It is a courageous thing to ask for help in overcoming
          difficulties so that you can build the life you would like. It is my
          hope that I can help you with this &mdash; that we can use the
          psychological understanding we develop together to consider your goals
          and how best these can be achieved, at a pace and manner that feels
          safe and comfortable for you.
        </p>
        <h2>How we achieve change</h2>
        <p>
          As a clinical psychologist, I draw on a number of therapeutic models
          and tools as well as on my knowledge of psychological functioning.
          Some of the therapeutic models I draw on include Cognitive Behaviour
          Therapy (CBT), third wave CBT approaches including mindfulness and
          acceptance and commitment therapy (ACT), Compassion Focussed Therapy
          (CFT), Cognitive Analytic Therapy (CAT). I am also trained in Eye
          Movement Desensitisation and Reprocessing (EMDR).
        </p>
        <p>
          The reason this list is varied is that we are all very different - I
          aim to develop a bespoke understanding of difficulties that can
          suggest possible routes of change. The route we choose will need to
          fit with you and your goals and therefore I offer a highly
          individualised approach to therapy.
        </p>
        <p>Difficulties that we can work on include:</p>
        <ul>
          <li>Depression or low mood</li>
          <li>Anxiety related difficulties, including panic</li>
          <li>Phobias</li>
          <li>Difficulties with regulating emotion</li>
          <li>OCD</li>
          <li>Trauma</li>
        </ul>
      </article>
      <div className="pt-6 pb-16">
        <p>
          If you want to talk to me in more detail about the services I offer then please <Link to="/contact/">contact me</Link>.
        </p>
      </div>
    </div>
  </Layout>
)

export default ServicesPage
